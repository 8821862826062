import React, { useContext } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { useAboutUsQuery } from "../../hooks/about-us/useAboutUsQuery"
import { translateContent } from "../../utils/translate-content"
export const AboutUs = () => {
  const { language } = useContext(GlobalStateContext)

  const {
    wpPage: {
      aboutUsCustomFields: {
        aboutUsContent,
        aboutUsContentPl,
        aboutUsTitle,
        aboutUsTitlePl,
        aboutUsContentBottomLinePl,
        aboutUsContentBottomLine
      }
    }
  } = useAboutUsQuery()

  return (
    <div className="content-wrapper about-us">
      <h2 className="content-wrapper__header">{translateContent(language, aboutUsTitle, aboutUsTitlePl)}</h2>
      <div className="about-us__container">
        <p>{translateContent(language, aboutUsContent, aboutUsContentPl)}</p>
        <p className="about-us__bottom-line">{translateContent(language, aboutUsContentBottomLine, aboutUsContentBottomLinePl)}</p>
      </div>
    </div>
  )
}
