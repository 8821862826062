import { graphql, useStaticQuery } from "gatsby";

export const useAboutUsQuery = () => {
  const data = useStaticQuery(graphql`
    query AboutUsQuery {
      wpPage(id: { eq: "cG9zdDoxNg==" }) {
        aboutUsCustomFields {
          aboutUsContent
          aboutUsContentPl
          aboutUsTitle
          aboutUsTitlePl
          aboutUsContentBottomLinePl
          aboutUsContentBottomLine
        }
      }
    }
  `);

  return data;
};
